import { Injectable } from '@angular/core';
import { Observable, Subject, take } from 'rxjs';
import dayjs from 'dayjs';

import { Time12To24Pipe } from '@pipes/time-12-to-24/time-12-to-24.pipe';
import { HOUR_INTERVALS } from '@core/constants/half-hour-intervals.constant';
import { TrainingSchedule } from '@models/training-schedule/training-schedule.model';
import {
  FULL_DAY_ITEM_ID,
  ITEM_TYPES,
} from '@core/constants/training-schedules.constant';
import { Course } from '@app/models/course.model';
import { TrainingScheduleIltCourse } from '@app/models/training-schedule/training-schedule-ilt-course.model';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { TrainingScheduleCohort } from '@app/models/training-schedule/training-schedule-cohort.model';
import { TrainingScheduleCohortItem } from '@app/models/training-schedule/training-schedule-cohort-item.model';
import { TrainingScheduleItem } from '@app/models/training-schedule/training-schedule-item.model';
import {
  IMoveTrainingScheduleItem,
  IMoveTrainingScheduleItemWithDay,
} from '@app/modules/training-schedules/interfaces/IMoveTrainingScheduleItem';
import { ApiService } from './api.service';
import { environment } from '@environment';
import { SnackBarService } from './snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ILT_COURSES_VIRTUAL_LOCATION_TYPE,
  INTEGRATION_TYPES,
} from '../constants/ilt-courses.constants';

interface ScheduleTime {
  hours: string;
  minutes: string;
}

@Injectable({
  providedIn: 'root',
})
export class TrainingScheduleService {
  private saveTrainingScheduleSubject = new Subject<string>();

  constructor(
    private api?: ApiService,
    private snackbarService?: SnackBarService,
    private translateService?: TranslateService,
  ) {}

  public saveTrainingSchedule(path: string): void {
    this.saveTrainingScheduleSubject.next(path);
  }

  public trainingScheduleSaveListener(): Observable<string> {
    return this.saveTrainingScheduleSubject.asObservable();
  }

  public getTrainingScheduleTime(time: string): ScheduleTime | null {
    if (!time) {
      return null;
    }

    let scheduleTime = time;
    if (time.length > 6) {
      // is AM/PM from BE
      scheduleTime = new Time12To24Pipe().transform(time);
    }

    return {
      hours: scheduleTime.substring(0, 2),
      minutes: scheduleTime.substring(3, 5),
    };
  }

  public getTrainingScheduleTimeString(time: string): string {
    if (!time) {
      return '';
    }

    const parsedTime = this.getTrainingScheduleTime(time);
    if (!parsedTime) {
      return '';
    }

    return `${parsedTime.hours}:${parsedTime.minutes}`;
  }

  public getDropTargetIds(schedule: TrainingSchedule): string[][] {
    let dropTargetIds: string[] = [];
    let intervals: string[] = [];
    const startTime = this.getTrainingScheduleTime(schedule.startTime);
    const endTime = this.getTrainingScheduleTime(schedule.endTime);

    if (!startTime || !endTime) {
      return [intervals, dropTargetIds];
    }

    let firstIntervalHourIdx = HOUR_INTERVALS.findIndex(
      interval => interval.substring(0, 2) === startTime.hours,
    );
    if (startTime.minutes >= '30') {
      firstIntervalHourIdx += 1;
    }

    let secondIntervalHourIdx = HOUR_INTERVALS.findIndex(
      interval => interval.substring(0, 2) === endTime.hours,
    );
    if (endTime.minutes > '30') {
      secondIntervalHourIdx += 2;
    } else if (endTime.minutes > '00') {
      secondIntervalHourIdx += 1;
    }

    const allIntervals = HOUR_INTERVALS.slice(
      firstIntervalHourIdx,
      secondIntervalHourIdx,
    );
    intervals =
      allIntervals[0].substring(3, 5) === '30'
        ? [allIntervals[0], ...allIntervals.filter((_a, idx) => idx % 2 === 1)]
        : [...allIntervals.filter((_a, idx) => idx % 2 === 0)];
    dropTargetIds = [FULL_DAY_ITEM_ID, ...allIntervals];

    return [intervals, dropTargetIds];
  }

  public getRoundedTrainingInterval(time: string): string {
    let firstIntervalHourIdx = HOUR_INTERVALS.findIndex(
      i => i.substring(0, 2) === time.substring(0, 2),
    );
    if (time.substring(3, 5) >= '30') {
      firstIntervalHourIdx += 1;
    }

    return HOUR_INTERVALS[firstIntervalHourIdx];
  }

  public getDurationInMinutes(endTime: string, startTime: string): number {
    dayjs.extend(customParseFormat);
    return dayjs(endTime, 'HH:mm').diff(dayjs(startTime, 'HH:mm'), 'minutes');
  }

  getCourseType(course: Course | TrainingScheduleIltCourse): string {
    if (course.modelConfig.type === 'courses') {
      return ITEM_TYPES.COURSE;
    }

    return ITEM_TYPES.SCHEDULED_ILT_COURSE;
  }

  setNestedPolymorphicRelationships(
    schedule: TrainingSchedule | TrainingScheduleCohort,
  ): void {
    // Workaround due to JSONAPI polymorphic nested include not working
    schedule.items?.forEach(item => {
      if (
        item.itemType !== ITEM_TYPES.SCHEDULED_ILT_COURSE &&
        item.itemType !== ITEM_TYPES.EVENT_DAY
      ) {
        return;
      }

      if (item.itemType === ITEM_TYPES.EVENT_DAY) {
        item.item.iltCourse = item.iltCourse;
        item.eventDay = undefined;
      } else {
        item.scheduledIltCourse = undefined;
      }
    });
  }

  onCourseDialogEdit(
    result: any,
    scheduleItem: TrainingScheduleItem | TrainingScheduleCohortItem,
    isCohort = false,
  ): IMoveTrainingScheduleItem | undefined {
    let interval: string | null = null;
    if (!result.allDay) {
      interval = this.getRoundedTrainingInterval(result.startTime);
    }

    if (
      interval === scheduleItem.startTimeFormatted &&
      (!isCohort || result.startDate === scheduleItem.date)
    ) {
      return;
    }

    const editedItem = {
      interval: !result.allDay ? interval : null,
      scheduleItem,
    };

    if (isCohort) {
      (editedItem as IMoveTrainingScheduleItemWithDay).day = dayjs(
        result.startDate,
      );
    }

    return editedItem;
  }

  onMeetingDialogEdit(
    result: any,
    scheduleItem: TrainingScheduleItem | TrainingScheduleCohortItem,
    calendarIntegrationType: string,
    isCohort = false,
  ): IMoveTrainingScheduleItem | undefined {
    let hasMeetingChanges = false;
    const duration = this.getDurationInMinutes(
      result.endTime,
      result.startTime,
    );
    const meetingType = result.generateLinkEnabled
      ? calendarIntegrationType === INTEGRATION_TYPES.GOOGLE
        ? ILT_COURSES_VIRTUAL_LOCATION_TYPE.GOOGLE
        : ILT_COURSES_VIRTUAL_LOCATION_TYPE.MICROSOFT
      : ILT_COURSES_VIRTUAL_LOCATION_TYPE.OTHER;
    // Prevent unnecesarry cohort item & meeting update
    // Trigger cohort item update if at least on meeting field changed
    if (
      scheduleItem.item.headline !== result.headline ||
      scheduleItem.item.description !== result.description ||
      scheduleItem.item.location !== result.location ||
      scheduleItem.item.organizerId.toString() !== result.organizer?.id ||
      scheduleItem.item.durationInMinutes !== duration ||
      scheduleItem.item.type !== meetingType
    ) {
      scheduleItem.item.headline = result.headline;
      scheduleItem.item.description = result.description;
      scheduleItem.item.location = result.location;
      scheduleItem.item.meetingOrganizer = result.organizer;
      scheduleItem.item.durationInMinutes = duration;
      scheduleItem.item.type = meetingType;
      hasMeetingChanges = true;
    }

    let interval: string | null = null;
    interval = this.getRoundedTrainingInterval(result.startTime);

    if (
      !hasMeetingChanges &&
      interval === scheduleItem.startTimeFormatted &&
      (!isCohort || result.startDate === scheduleItem.date)
    ) {
      return;
    }

    const editedItem = {
      interval,
      scheduleItem,
    };

    if (isCohort) {
      (editedItem as IMoveTrainingScheduleItemWithDay).day = dayjs(
        result.startDate,
      );
    }

    return editedItem;
  }

  onBlockDialogEdit(
    result: any,
    scheduleItem: TrainingScheduleItem | TrainingScheduleCohortItem,
    isCohort = false,
  ): IMoveTrainingScheduleItem | IMoveTrainingScheduleItemWithDay | undefined {
    let hasBlockChanges = false;
    const duration = this.getDurationInMinutes(
      result.endTime,
      result.startTime,
    );
    // Prevent unnecesarry cohort item & meeting update
    // Trigger cohort item update if at least on meeting field changed
    if (
      scheduleItem.item.headline !== result.headline ||
      scheduleItem.item.description !== result.description ||
      scheduleItem.item.durationInMinutes !== duration ||
      scheduleItem.item.isBusy !== result.isBusy
    ) {
      scheduleItem.item.headline = result.headline;
      scheduleItem.item.description = result.description;
      scheduleItem.item.isBusy = result.isBusy;
      scheduleItem.item.durationInMinutes = duration;
      hasBlockChanges = true;
    }

    let interval: string | null = null;
    interval = this.getRoundedTrainingInterval(result.startTime);

    if (
      !hasBlockChanges &&
      interval === scheduleItem.startTimeFormatted &&
      (!isCohort || result.startDate === scheduleItem.date)
    ) {
      return;
    }

    const editedItem = {
      interval,
      scheduleItem,
    };

    if (isCohort) {
      (editedItem as IMoveTrainingScheduleItemWithDay).day = dayjs(
        result.startDate,
      );
    }

    return editedItem;
  }

  duplicateTrainingSchedule(id: string): Observable<void> {
    const subject = new Subject<void>();
    const path = `/api/v1/schedules/${id}/duplicate`;
    this.api
      ?.post(environment.backendBaseUrl, path, {})
      .pipe(take(1))
      .subscribe(() => {
        this.snackbarService?.success(
          this.translateService?.instant('trainingSchedules.duplicateSuccess'),
        );
        subject.next();
      });

    return subject.asObservable();
  }
}
