<mat-toolbar class="footer-bar" fxLayout="row" [fxLayoutGap]="actions?.buttons?.length ? '32px' : '0'" *ngIf="actions?.buttons?.length">
  <ng-container *ngFor="let button of actions?.buttons">
    <button
      *ngIf="!hideAction(button.name)"
      mat-stroked-button
      [color]="button.color"
      [ngClass]='button?.class || []'
      [disabled]="disableAction(button.name)"
      (click)="runAction(button.action)"
    >
      {{ (button?.label || '') | translate }}
    </button>
  </ng-container>
</mat-toolbar>
