import '@angular/compiler';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialCssVarsModule } from 'angular-material-css-vars';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '@environment';

// translate
import { TranslationLoaderModule } from '@shared/translation-loader/translation-loader.module';

// Services
import { ApiService } from '@services/api.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { ErrorInterceptor } from '@core/interceptors/error.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '@services/auth.service';
import { ErrorModule } from '@shared/error/module';
import { ErrorService } from '@services/error.service';
import { NotificationModule } from '@shared/notification/module';
import { SnackBarModule } from '@shared/snack-bar/snack-bar-module';
import { HeaderModule } from '@shared/header/header.module';

import { AppComponent } from './app.component';
import { IconsModule } from '@shared/icons/icons.module';
import { JsonApiModule } from '@elearnio/angular2-jsonapi';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AlertModule } from '@shared/alert';
import { GaugeModule } from 'angular-gauge';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ElearnioLoaderComponent } from '@shared/elearnio-loader/elearnio-loader.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppSidebarModule } from '@shared/app-sidebar/app-sidebar.module';
import { AppToolbarModule } from '@app/shared/app-toolbar/app-toolbar.module';
import { NavigationSidebarModule } from '@shared/navigation-sidebar/navigation-sidebar.module';
import { MobileActionsBarModule } from '@shared/mobile-actions-bar/mobile-actions-bar.module';
import { appInitializer } from '@core/app.initializer';
import { Router } from '@angular/router';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import {
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { CompanySettingService } from '@services/company-setting.service';

export const appImports = [
  BrowserModule,
  BrowserAnimationsModule,
  AppRoutingModule,
  JsonApiModule,
  ErrorModule,
  NotificationModule,
  SnackBarModule,
  HeaderModule,
  MatDialogModule,
  IconsModule,
  TranslationLoaderModule,
  TranslateModule,
  MaterialCssVarsModule.forRoot(),
  MatProgressBarModule,
  FlexLayoutModule,
  AlertModule,
  GaugeModule.forRoot(),
  NgHttpLoaderModule.forRoot(),
  MatSidenavModule,
  AppSidebarModule,
  AppToolbarModule,
  MsalModule,
];

export function MSALInstanceFactory(
  companySettingService: CompanySettingService,
): IPublicClientApplication {
  const company = companySettingService.getCompany();
  const msIntegration = company?.msTeamsIntegration;
  const clientId = msIntegration?.api_client_id || 'invalid';
  const tenantId = msIntegration?.api_tenant_id || 'invalid';

  return new PublicClientApplication({
    auth: {
      clientId,
      authority: `https://login.microsoftonline.com/${tenantId}`,
    },
  });
}

@NgModule({
  declarations: [
    AppComponent, // main app component
    ElearnioLoaderComponent,
  ],
  imports: [...appImports, NavigationSidebarModule, MobileActionsBarModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorService,
    },
    {
      provide: MSAL_INSTANCE,
      deps: [CompanySettingService],
      useFactory: MSALInstanceFactory,
    },
    MsalService,
    ApiService,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService, Router],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: 'googleTagManagerId',
      useValue: environment.googleTagManagerId,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
