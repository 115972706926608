import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import merge from 'deepmerge';
import { TranslateLoader } from '@ngx-translate/core';

export interface ITranslationResource {
  prefix: string;
  suffix: string;
}

export class JsonTranslateHttpMultiLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private resources: ITranslationResource[],
  ) {}

  public static factory(http: HttpClient): JsonTranslateHttpMultiLoader {
    const date = new Date();
    const cacheBuster = `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`;
    return new JsonTranslateHttpMultiLoader(http, [
      {
        prefix: '/assets/i18n/generated-json/',
        suffix: `/shared.json?version=${cacheBuster}`,
      },
      {
        prefix: '/assets/i18n/generated-json/',
        suffix: `/frontend.json?version=${cacheBuster}`,
      },
      {
        prefix: '/assets/i18n/generated-json/',
        suffix: `/error-rules.json?version=${cacheBuster}`,
      },
    ]);
  }

  getTranslation(lang: string): Observable<unknown> {
    const requests = this.resources.map(resource => {
      const path = resource.prefix + lang + resource.suffix;

      return this.http
        .get(path, { responseType: 'text' })
        .pipe(map((contents: string) => JSON.parse(contents)[lang]));
    });

    return forkJoin(requests).pipe(map(response => merge.all(response)));
  }
}
