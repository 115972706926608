import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Member } from '@models/member.model';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthService } from '@services/auth.service';
import { Section } from '@core/constants/section.constants';
import { GamificationRank } from '@models/gamification/gamification-rank.model';
import { PermissionService } from '@services/permission.service';
import { IPermission } from '@app/core/interfaces/permission.interface';
import { CompanySetting } from '@app/models/company-setting.model';
import { FeatureFlagService } from '@services/feature-flag.service';
import { take } from 'rxjs';
import { Roles } from '@app/core/constants/roles.constant';
import { FEATURES } from '@core/constants/features.constant';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.scss'],
})
export class CoursesCategoriesSidebarComponent implements OnInit {
  @Input() member: Member | undefined;
  @Input() section: Section | undefined;
  @Input() isSidebarExpanded = true;
  @Input() companySettings!: CompanySetting;
  ROLES = Roles;
  SECTIONS = Section;
  mobileQuery: MediaQueryList;
  gamificationTop: GamificationRank[] = [];
  coursePermissions: IPermission = {};
  hasTrainingScheduleFeature = false;

  private mobileQueryListener: () => void;

  constructor(
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private authService: AuthService,
    private permissionService: PermissionService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  get hasAssignedCohort(): boolean {
    return !!this.permissionService.getMemberPermissions()?.assigned_cohort;
  }

  ngOnInit(): void {
    this.featureFlagService
      .isFeatureFlagEnabled(FEATURES.TRAINING_SCHEDULE)
      .pipe(take(1))
      .subscribe(isEnabled => {
        this.hasTrainingScheduleFeature = isEnabled;
      });

    this.coursePermissions = this.permissionService.getCoursePermissions();
  }

  editCategory(id: string | undefined): void {
    if (id) {
      this.router.navigate([`/categories/${id}/profile`]);
    }
  }

  showAdminArea(): boolean {
    return (
      this.section !== this.SECTIONS.COURSES &&
      this.section !== this.SECTIONS.CATEGORIES
    );
  }

  hasCourseCreatePermission(): boolean {
    return !!this.permissionService.getCoursePermissions()?.create;
  }

  hasInstructorViewPermission(): boolean {
    return !!this.permissionService.getMemberPermissions()?.instructor;
  }

  hasTaskReviewerViewPermission(): boolean {
    return !!this.permissionService.getMemberPermissions()?.reviewer;
  }

  hasMemberUpdatePermission(): boolean {
    return !!this.permissionService.getMemberPermissions()?.update;
  }

  hasTrainingScheduleCreatePermission(): boolean {
    if (!this.hasTrainingScheduleFeature) {
      return false;
    }

    return !!this.permissionService.getTrainingSchedulePermissions()?.create;
  }

  hasCoursePermission(): boolean {
    const retVal =
      this.permissionService.getCoursePermissions().admin_access ||
      this.member?.role === this.ROLES.AUTHOR;
    if (typeof retVal !== 'undefined') {
      return retVal;
    } else {
      return true;
    }
  }

  hasIltCoursePermission(): boolean {
    const retVal =
      this.permissionService.getIltCoursePermissions().admin_access ||
      this.member?.role === this.ROLES.AUTHOR;
    if (typeof retVal !== 'undefined') {
      return retVal;
    } else {
      return true;
    }
  }

  hasLPPermission(): boolean {
    const retVal =
      this.permissionService.getLPPermissions().admin_access ||
      this.member?.role === this.ROLES.AUTHOR;
    if (typeof retVal !== 'undefined') {
      return retVal;
    } else {
      return true;
    }
  }

  hasTrainingSchedulePermission(): boolean {
    const retVal =
      this.permissionService.getTrainingSchedulePermissions().admin_access;
    if (typeof retVal !== 'undefined') {
      return retVal;
    } else {
      return true;
    }
  }

  hasCategoriesPermission(): boolean {
    const retVal =
      this.permissionService.getCategoriesPermissions().admin_access;
    if (typeof retVal !== 'undefined') {
      return retVal;
    } else {
      return true;
    }
  }

  logout(): void {
    this.authService.logout();
  }

  getGroupPermissionUpdate(): boolean {
    return this.permissionService.getGroupPermissions()?.update || false;
  }

  getPermissionsService(): PermissionService {
    return this.permissionService;
  }

  getAssignPermission(): boolean {
    return (
      this.getPermissionsService().getOrganizationPermissions()
        .assign_all_entities || false
    );
  }

  getAdminPermission(): boolean {
    return (
      this.getPermissionsService().getOrganizationPermissions()
        .admin_permission || false
    );
  }

  getPeopleManagementPermission(): boolean {
    return (
      this.getPermissionsService().getOrganizationPermissions()
        .people_management || false
    );
  }
}
