import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Section } from '@core/constants/section.constants';
import { IToolbarBreadcrumb } from './interfaces';
import { ImportCourseDialogComponent } from '@shared/import-course-dialog/import-course-dialog.component';
import { MemberInviteEmailDialogComponent } from '@shared/member-invite-email-dialog/member-invite-email-dialog.component';
import { MemberInviteCsvDialogComponent } from '@shared/member-invite-csv-dialog/member-invite-csv-dialog.component';
import { ToolbarActionsService } from '@services/toolbar-actions.service';
import {
  RouteActionButtonInterface,
  RouteActionInterface,
} from '@core/interfaces/route-action.interface';

@UntilDestroy()
@Component({
  selector: 'app-toolbar',
  templateUrl: './app-toolbar.component.html',
  styleUrls: ['./app-toolbar.component.scss'],
})
export class AppToolbarComponent implements OnInit {
  @Input() section: Section | undefined;
  @Input() breadcrumbs: IToolbarBreadcrumb[] = [];
  @Input() disableActions: string[] = [];
  @Input() hideActions: string[] = [];
  @Input() isSidebarExpanded = true;
  @Input() hideMenuButton = false;
  @Input() customNewButtonRouterLinks: Record<string, string> = {};
  @Output() actionOne = new EventEmitter<boolean>();
  @Output() actionTwo = new EventEmitter<boolean>();
  @Output() actionThree = new EventEmitter<boolean>();
  @Output() actionFour = new EventEmitter<boolean>();
  @Output() sidebarClick = new EventEmitter<void>();
  actions!: RouteActionInterface | undefined;

  constructor(
    private dialog: MatDialog,
    private toolbarActionsService: ToolbarActionsService,
    private router: Router,
  ) {
    this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.actions = this.toolbarActionsService.getActionsForCurrentRoute(
          event.url,
        );
      }
    });
  }

  ngOnInit(): void {
    this.actions = this.toolbarActionsService.getActionsForCurrentRoute(
      this.router.url,
    );
  }

  isSettingsSection(): boolean {
    return this.section === Section.SETTINGS;
  }

  onSideToolbarClick(): void {
    this.sidebarClick.emit();
  }

  doActionOne(): void {
    this.actionOne.emit(true);
  }

  doActionTwo(): void {
    this.actionTwo.emit(true);
  }

  doActionThree(): void {
    this.actionThree.emit(true);
  }

  doActionFour(): void {
    this.actionFour.emit(true);
  }

  importCourse(): void {
    this.dialog.open(ImportCourseDialogComponent, {
      autoFocus: false,
      panelClass: 'elearnio-mat-dialog-panel-class',
    });
  }

  inviteByEmail(): void {
    this.dialog.open(MemberInviteEmailDialogComponent, {
      autoFocus: false,
      panelClass: 'elearnio-mat-dialog-panel-class',
    });
  }

  inviteByCsv(): void {
    this.dialog.open(MemberInviteCsvDialogComponent, {
      autoFocus: false,
      panelClass: 'elearnio-mat-dialog-panel-class',
    });
  }

  runAction(action: string | undefined): void {
    if (!action) {
      return;
    }
    // typeof this[action] === 'function' throws typescript errors
    switch (action) {
      case this.importCourse.name:
        this.importCourse();
        break;
      case this.inviteByEmail.name:
        this.inviteByEmail();
        break;
      case this.inviteByCsv.name:
        this.inviteByCsv();
        break;
      case this.doActionOne.name:
        this.doActionOne();
        break;
      case this.doActionTwo.name:
        this.doActionTwo();
        break;
      case this.doActionThree.name:
        this.doActionThree();
        break;
      case this.doActionFour.name:
        this.doActionFour();
        break;
    }
  }

  disableAction(action: string | undefined): boolean {
    return !!action && !!this.disableActions.includes(action);
  }

  hideAction(button: RouteActionButtonInterface): boolean {
    if (button.canBeHidden && this.hideMenuButton) {
      return true;
    }

    return !!button.name && !!this.hideActions.includes(button.name);
  }

  getButtonRouterLink(button: RouteActionButtonInterface): string[] {
    return button.name && this.customNewButtonRouterLinks[button.name]
      ? [this.customNewButtonRouterLinks[button.name]]
      : button.routerLink
      ? [button.routerLink]
      : [];
  }
}
