import { Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { RoleGuard } from '@guards/role.guard';
import { TalentsGuard } from '@guards/talents.guard';
import { ReportingGuard } from '@guards/reporting.guard';

import { Page404Component } from '@modules/page404/page404.module';
import { CourseRoleGuard } from '@modules/courses/guards/course-role.guard';
import { H5pPlayerStandaloneComponent } from '@shared/h5p/components/h5p-player-standalone/h5p-player-standalone.component';

// Constants
import { Section } from '@core/constants/section.constants';
import { SidebarGuard } from '@guards/sidebar-guard.service';
import { HeaderGuard } from '@guards/header-guard.service';

// keep the routes clean
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'categories',
    canActivate: [AuthGuard, SidebarGuard],
    loadChildren: () =>
      import('@modules/categories/categories.module').then(
        m => m.CategoriesModule,
      ),
    data: {
      sidebar: { show: true, section: Section.COURSES },
    },
  },
  {
    path: 'courses',
    canActivate: [AuthGuard, SidebarGuard, HeaderGuard],
    loadChildren: () =>
      import('@modules/courses/courses.module').then(m => m.CoursesModule),
    data: {
      sidebar: { show: true, section: Section.COURSES },
    },
  },
  {
    path: 'ilt-courses',
    canActivate: [AuthGuard, SidebarGuard, HeaderGuard],
    loadChildren: () =>
      import('@modules/ilt-courses/ilt-courses.module').then(
        m => m.ILTCoursesModule,
      ),
    data: {
      sidebar: { show: true, section: Section.COURSES },
    },
  },
  {
    path: 'learning-paths',
    canActivate: [AuthGuard, SidebarGuard, HeaderGuard],
    loadChildren: () =>
      import('@modules/learning-paths/learning-paths.module').then(
        m => m.LearningPathsModule,
      ),
    data: {
      sidebar: { show: true, section: Section.COURSES },
    },
  },
  {
    path: 'groups',
    canActivate: [AuthGuard, SidebarGuard],
    loadChildren: () =>
      import('@modules/groups/groups.module').then(m => m.GroupsModule),
    data: {
      sidebar: { show: true, section: Section.TALENTS },
    },
  },
  {
    path: 'organizations',
    canActivate: [AuthGuard, SidebarGuard],
    loadChildren: () =>
      import('@modules/organizations/organizations.module').then(
        m => m.OrganizationsModule,
      ),
    data: {
      sidebar: { show: true, section: Section.TALENTS },
    },
  },
  {
    path: 'my',
    canActivate: [AuthGuard, SidebarGuard],
    loadChildren: () => import('@modules/my/my.module').then(m => m.MyModule),
    data: {
      sidebar: { show: false, section: Section.DASHBOARD },
    },
  },
  {
    path: 'settings',
    canActivate: [AuthGuard, CourseRoleGuard, RoleGuard],
    data: {
      roles: {
        resource: 'CompanySetting',
        key: 'update',
      },
      sidebar: { section: Section.SETTINGS },
    },
    loadChildren: () =>
      import('@modules/settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: 'talents',
    loadChildren: () =>
      import('@modules/talents/talents.module').then(m => m.TalentsModule),
    data: {
      roles: {
        resource: 'Member',
        key: 'update',
      },
      sidebar: { show: true, section: Section.TALENTS },
    },
    canActivate: [AuthGuard, TalentsGuard, SidebarGuard],
  },
  {
    path: 'reporting',
    loadChildren: () =>
      import('@modules/reporting/reporting.module').then(
        m => m.ReportingModule,
      ),
    data: {
      roles: {
        resource: 'CourseTalentReport',
        key: 'index',
      },
      sidebar: {
        show: true,
        section: Section.REPORTING,
      },
    },
    canActivate: [AuthGuard, RoleGuard, SidebarGuard, ReportingGuard],
  },
  {
    path: 'content',
    component: H5pPlayerStandaloneComponent,
    data: {
      hideHeader: true,
    },
    canActivate: [HeaderGuard],
  },
  {
    path: 'training-schedules',
    canActivate: [AuthGuard, SidebarGuard, HeaderGuard],
    loadChildren: () =>
      import('@modules/training-schedules/training-schedules.module').then(
        m => m.TrainingSchedulesModule,
      ),
    data: {
      sidebar: { show: true, section: Section.TRAINING_SCHEDULES },
    },
  },
  { path: '404', component: Page404Component },
  { path: '**', component: Page404Component },
];
