import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Section } from '@core/constants/section.constants';
import { IToolbarBreadcrumb } from '@app/shared/app-toolbar/interfaces';

export interface ISidebarConfig {
  section: Section | undefined;
  show: boolean;
  hideSidebar: boolean;
  disableActions: string[] | undefined;
  hideActions: string[] | undefined;
  breadcrumbs: IToolbarBreadcrumb[] | undefined;
  hideMenuButton: boolean;
  customNewButtonRouterLinks: Record<string, string>;
  actionOne: (() => void) | undefined;
  actionTwo: (() => void) | undefined;
  actionThree: (() => void) | undefined;
  actionFour: (() => void) | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class SideAndTopBarService {
  data = new ReplaySubject<ISidebarConfig>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  currentData: ISidebarConfig;

  constructor() {
    this.setValues({});
  }

  setValues(data: Partial<ISidebarConfig>): void {
    this.currentData = {
      show: data.show || false,
      hideSidebar: data.hideSidebar || false,
      section: data.section,
      breadcrumbs: data.breadcrumbs || undefined,
      actionTwo: data.actionTwo || undefined,
      actionThree: data.actionThree || undefined,
      actionOne: data.actionOne || undefined,
      actionFour: data.actionFour || undefined,
      disableActions: data.disableActions || [],
      customNewButtonRouterLinks: data.customNewButtonRouterLinks || {},
      hideActions: data.hideActions || [],
      hideMenuButton:
        typeof data.hideMenuButton !== 'undefined'
          ? data.hideMenuButton
          : !!this.currentData?.hideMenuButton,
    };
    this.data.next(this.currentData);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setValue(key: string, value: any): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.currentData[key] = value;
    this.data.next(this.currentData);
  }

  mergeValues(data: Partial<ISidebarConfig>): void {
    this.currentData = Object.assign(this.currentData, data);
    this.data.next(this.currentData);
  }
}
