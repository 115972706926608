import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { MediaMatcher } from '@angular/cdk/layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { NotificationService } from '@services/notification.service';
import { AuthService } from '@services/auth.service';
import { ColorService } from '@services/color.service';
import { MemberService } from '@services/member.service';
import { DrawerService } from '@services/drawer.service';
import { GtmWrapperService } from '@services/gtm-wrapper.service';
import { LanguageService } from '@services/language.service';
import { SideAndTopBarService } from '@services/side-and-top-bar.service';
import { HeaderService } from '@services/header.service';
import { CompanySettingService } from '@services/company-setting.service';
import { PermissionService } from '@services/permission.service';
import { Member } from '@models/member.model';
import { CompanySetting } from '@models/company-setting.model';
import { CompanyConsentSetting } from '@models/company/company-consent-settings.model';
import { IToolbarBreadcrumb } from '@app/shared/app-toolbar/interfaces';
import { ElearnioLoaderComponent } from '@shared/elearnio-loader/elearnio-loader.component';
import { Section } from '@core/constants/section.constants';
import { Roles } from '@core/constants/roles.constant';
import { environment } from '@environment';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@shared/confirm-dialog/confirm-dialog.component';
import { Datastore } from '@services/datastore';
import { BrandPalette } from '@models/branding/brand-palette.model';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { EditPasswordDialogComponent } from '@shared/edit-password/edit-password-dialog.component';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { EditPasswordDialogModule } from '@shared/edit-password/edit-password-dialog.module';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { EditCurrentPasswordDialogModule } from '@shared/edit-current-password/edit-current-password-dialog.module';
import { EditCurrentPasswordDialogComponent } from '@app/shared/edit-current-password/edit-current-password-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('snav') snav: MatDrawer | undefined;

  mobileQuery: MediaQueryList;
  showBars = false;
  hideSidebar = false;
  member: Member | undefined;
  isAdmin = false;
  section: Section | undefined;
  ROLES = Roles;
  SECTIONS = Section;
  companySettings!: CompanySetting;

  public isLoggedIn = false;
  public loader = ElearnioLoaderComponent;
  public dontUseLoaderOnRoutes = [
    'activity_answers',
    'members/(.*)\\?include=gamification',
    'member_notifications',
    'users/login',
    'users/refresh_token',
    'users/logout',
    environment.s3BucketBaseUrl,
    'course/import',
    'certificates',
    'attendances',
    `${environment.h5pBaseUrl}/content-creator`,
  ];
  disableActions: string[] = [];
  hideActions: string[] = [];
  mobileQueryListener: () => void;
  breadcrumbs: IToolbarBreadcrumb[] = [];
  customNewButtonRouterLinks: Record<string, string> = {};
  actionOne: () => void;
  actionTwo: () => void;
  actionThree: () => void;
  actionFour: () => void;
  showHeader = true;
  showGamificationBadge = false;
  isSidebarExpanded = true;
  hideMenuButton = false;
  logo = '';

  private defaultLogo = '/assets/images/elearnio-logo-retina.png';

  constructor(
    public notify: NotificationService,
    private colorService: ColorService,
    private auth: AuthService,
    private router: Router,
    private gtmService: GtmWrapperService,
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private drawerService: DrawerService,
    private memberService: MemberService,
    private sidebarService: SideAndTopBarService,
    private headerService: HeaderService,
    private companySettingService: CompanySettingService,
    private languageService: LanguageService,
    private permissionService: PermissionService,
    private translateService: TranslateService,
    private datastore: Datastore,
    private dialog: MatDialog,
  ) {
    // this.authService.refreshToken();
    this.mobileQuery = media.matchMedia('(max-width: 959px)');
    this.isSidebarExpanded = media.matchMedia('(min-width: 1280px)').matches;
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    // noinspection JSDeprecatedSymbols
    this.mobileQuery.addListener(this.mobileQueryListener);
    this.drawerService.toggle
      .pipe(untilDestroyed(this))
      .subscribe(opened => this.snav?.toggle(opened));

    this.actionOne = () => {};
    this.actionThree = () => {};
    this.actionTwo = () => {};
    this.actionFour = () => {};
    this.checkPath();
    languageService.setLanguage();
  }

  ngOnInit(): void {
    this.companySettingService.companySettingListener
      .pipe(untilDestroyed(this))
      .subscribe(companySettings => {
        this.companySettings = companySettings;
      });

    this.sidebarService.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.section = data.section;
      this.showBars = data.show;
      this.hideSidebar = data.hideSidebar;
      this.disableActions = data.disableActions || [];
      this.customNewButtonRouterLinks = data.customNewButtonRouterLinks || {};
      this.hideActions = data.hideActions || [];
      this.actionOne = data.actionOne || (() => {});
      this.actionTwo = data.actionTwo || (() => {});
      this.actionThree = data.actionThree || (() => {});
      this.actionFour = data.actionFour || (() => {});
      this.breadcrumbs = data.breadcrumbs || [];
      this.changeDetectorRef.detectChanges();
      this.hideMenuButton = data.hideMenuButton;
    });

    this.headerService.data.pipe(untilDestroyed(this)).subscribe(data => {
      if (typeof data.showHeader === 'boolean') {
        this.showHeader = data.showHeader;
      }
      if (data.logo && typeof data.logo === 'string') {
        this.logo = data.logo;
      } else {
        this.logo = this.defaultLogo;
      }
      if (typeof data.showGamificationBadge === 'boolean') {
        this.showGamificationBadge = data.showGamificationBadge;
      }
      if (
        this.member &&
        data.profilePicture &&
        typeof data.profilePicture === 'string'
      ) {
        this.member.picture = data.profilePicture;
      }
    });

    this.attachTagmanagerToRouterEvents();
    this.auth.registerLoginCallback(() => this.onLoggedIn());
    this.auth.registerLogoutCallback(() => this.onLoggedOut());
  }

  isDefaultBrandColor(): boolean {
    return this.colorService.isDefaultBrandColor();
  }

  onSidebarClick(): void {
    this.isSidebarExpanded = !this.isSidebarExpanded;
  }

  getPeopleManagementPermission(): boolean {
    return (
      this.permissionService.getOrganizationPermissions().people_management ||
      false
    );
  }

  private onLoggedIn(): void {
    this.isLoggedIn = true;

    const requests = [];
    requests.push(this.memberService.current());
    requests.push(this.companySettingService.current());
    combineLatest(requests)
      .pipe(take(1))
      .subscribe(([member, settings]) => {
        const brandPalette: BrandPalette = {
          logo: member?.logo,
          favicon: member?.favicon,
          brandPaletteName: member?.brandingName,
          brandColor: member?.brandColor,
          interactiveElementsColor: member?.interactiveElementsColor,
          mainButtonColor: member?.mainButtonColor,
          buttonTextColor: member?.buttonTextColor,
        } as unknown as BrandPalette;

        let companyDefaultLang = '';
        let memberLang = '';

        this.colorService.setBrandColor(brandPalette);
        this.logo = brandPalette.logo || this.defaultLogo;
        this.headerService.changeFavicon(brandPalette.favicon);

        if (settings && settings instanceof CompanySetting) {
          this.companySettings = settings;

          companyDefaultLang =
            settings.defaultLanguage ||
            this.languageService.getCurrentLanguage();

          this.showGamificationBadge = settings.showGamificationBadge;
          this.headerService.setValues({
            logo: this.logo,
            showGamificationBadge: this.showGamificationBadge,
            showHeader: this.showHeader,
          });
        }

        if (member && member instanceof Member) {
          this.member = member;
          memberLang =
            this.member.language || this.languageService.getCurrentLanguage();
          this.isAdmin =
            this.member.role === this.ROLES.ADMIN ||
            this.member.role === this.ROLES.GLOBAL_COACH;
          this.headerService.setValue('profilePicture', member.picture);
        }

        this.languageService.setMemberLanguage(memberLang, companyDefaultLang);
        this.permissionService.setGeneralPermissions();

        if (
          settings?.enableLoginUsername &&
          member &&
          member instanceof Member &&
          member.forcePasswordChange
        ) {
          this.showEditPasswordDialog(member);
        } else if (settings?.consent && member && !member.consent) {
          this.getConsentsAndShowDialog();
        }
      });

    this.memberService
      .pollCurrent()
      .pipe(untilDestroyed(this))
      .subscribe(member => {
        if (member) {
          this.member = member;
        }
      });
  }

  private showEditPasswordDialog(member: Member): void {
    const dialogRef = this.dialog.open(EditCurrentPasswordDialogComponent, {
      data: {
        title: this.translateService.instant('reset-password.editYourPassword'),
        newPassword: this.translateService.instant(
          'reset-password.newPassword',
        ),
        confirmPassword: this.translateService.instant(
          'reset-password.confirmNewPassword',
        ),
        cancel: this.translateService.instant('common.cancel'),
        confirm: this.translateService.instant('common.save'),
        member,
      },
      disableClose: true,
      panelClass: 'elearnio-mat-dialog-panel-class',
    });

    dialogRef.afterClosed().subscribe(() => {
      if (this.companySettings?.consent && member && !member.consent) {
        this.getConsentsAndShowDialog();
      }
    });
  }

  private getConsentsAndShowDialog(): void {
    this.datastore
      .findAll(CompanyConsentSetting, {
        filter: { language: this.languageService.getCurrentLanguage() },
      })
      .pipe(take(1))
      .subscribe(consents => {
        const currentConsent = consents.getModels()[0];
        if (currentConsent) {
          this.showConsentDialog(currentConsent.companyConsent)
            .pipe(take(1))
            .subscribe(result => {
              if (result) {
                if (!this.member) {
                  return;
                }
                this.member.consent = true;
                this.member.consentDate = new Date();
                this.member.save().pipe(take(1)).subscribe();
              } else {
                this.auth.logout();
              }
            });
        }
      });
  }

  private showConsentDialog(message: string): Observable<boolean> {
    const dialogData: ConfirmDialogData = {
      message,
      title: this.translateService.instant('common.userAgreement'),
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
      backdropClass: 'company-consent-dialog-backdrop',
      panelClass: 'elearnio-mat-dialog-panel-class',
    });

    return dialogRef.afterClosed();
  }

  private onLoggedOut(): void {
    this.isLoggedIn = false;
    this.member = undefined;
  }

  private attachTagmanagerToRouterEvents(): void {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  private checkPath(): void {
    if (
      window.location.host.includes(environment.domain) &&
      !window.location.hash
    ) {
      const splitUrl = window.location.href.split(`${environment.domain}/`);
      if (splitUrl.length < 2) {
        return;
      }

      window.location.href = `${splitUrl[0]}${environment.domain}/#/${splitUrl[1]}`;
      return;
    }
  }
}
