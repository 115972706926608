import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'member_reports',
})
export class MemberReport extends JsonApiModel {
  @Attribute({ serializedName: 'member_id' })
  memberId!: string;

  @Attribute({ serializedName: 'full_name' })
  memberName!: string;

  @Attribute({ serializedName: 'last_activity' })
  lastActivity!: Date;

  @Attribute({ serializedName: 'groups_count' })
  groupsCount!: number;

  @Attribute({ serializedName: 'courses_count' })
  coursesCount!: number;

  @Attribute({ serializedName: 'courses_running' })
  coursesRunning!: number;

  @Attribute({ serializedName: 'courses_missed' })
  coursesMissed!: number;

  @Attribute({ serializedName: 'courses_completed' })
  coursesCompleted!: number;

  @Attribute({ serializedName: 'time_spent_in_seconds' })
  timeSpentInSeconds!: number;

  @Attribute({ serializedName: 'net_time_in_seconds' })
  netTimeInSeconds!: number;

  @Attribute({ serializedName: 'gamification_score' })
  gamificationScore!: number;

  @Attribute({ serializedName: 'course_progress_avg' })
  courseProgressAvg!: string;

  @Attribute({ serializedName: 'course_completion_avg' })
  courseCompletionAvg!: string;

  @Attribute({ serializedName: 'course_success_avg' })
  courseSuccessAvg!: string;

  @Attribute({ serializedName: 'course_score_avg' })
  courseScoreAvg!: string;

  @Attribute({ serializedName: 'fullpath' })
  orgaFullPath!: string;

  @Attribute({ serializedName: 'level_number' })
  orgaLevel!: string;

  @Attribute({ serializedName: 'orga_name' })
  orgaName!: string;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;
}
