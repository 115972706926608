<mat-toolbar *ngIf="!!actions" class="top-bar"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             [class.settings]="isSettingsSection()"
>
  <div *ngIf="section" [class.expanded]="isSidebarExpanded" class="title" fxLayout="row" fxLayoutAlign="start center">
    <div class="title-box" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" (click)="onSideToolbarClick()">
      <mat-icon>{{ isSidebarExpanded ? 'menu_open' : 'menu' }}</mat-icon>
      <span class="sidebar-item-span">{{ ('sidebar.' + section) | translate }}</span>
    </div>
  </div>

  <div class="actions-container" fxLayoutAlign="space-between center">
    <div class="breadcrumbs" fxLayoutAlign="space-between center">
      <div *ngFor="let crumb of breadcrumbs; let index = index;" class="breadcrumb-item">
        <a [routerLink]="crumb.url" [class.current]="index === (breadcrumbs.length - 1)">
          {{ crumb.key | translate: crumb.args }}
        </a>
        <mat-icon *ngIf="index < (breadcrumbs.length - 1)">chevron_right</mat-icon>
      </div>
    </div>
    <div class="cta" fxLayout="row" [fxLayoutGap]="actions?.buttons?.length ? '16px' : '0'">
      <ng-container *ngFor="let button of actions?.buttons">
        <button
          *ngIf="!hideAction(button)"
          mat-stroked-button
          [color]="button.color"
          class="action-button"
          [ngClass]='button?.class || []'
          [disabled]="disableAction(button.name)"
          [routerLink]="getButtonRouterLink(button)"
          (click)="runAction(button.action)"
        >
          <mat-icon *ngIf="button?.icon" class="size-m" aria-hidden="true">{{ button.icon }}</mat-icon>
          {{ (button?.label || '') | translate }}
        </button>
      </ng-container>
    </div>
  </div>
</mat-toolbar>
