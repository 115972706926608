<ng-http-loader [filteredUrlPatterns]="dontUseLoaderOnRoutes"
                [entryComponent]="loader"></ng-http-loader>
<ng-template [ngIf]="isLoggedIn" [ngIfElse]="notLoggedIn">
  <app-header [ngClass]="{ 'hide': !showHeader }" [currentMember]="member" [isDefaultBrandColor]="isDefaultBrandColor()" [logo]="logo" [showGamificationBadge]="showGamificationBadge"></app-header>
  <app-alert></app-alert>
  <main class="main-layout overflow-y-auto" [class.full-height]="!showHeader" ngClass.lt-md="mobile">
    <div fxLayout="column" class="content-container" ngClass.gt-md="desktop-minimum-width">
      <div fxFlex *ngIf="!mobileQuery.matches">
        <header>
          <app-toolbar [section]="section" [breadcrumbs]="breadcrumbs" [disableActions]="disableActions" [hideActions]="hideActions"
                        (actionOne)="actionOne()" (actionTwo)="actionTwo()" (actionThree)="actionThree()" (actionFour)="actionFour()"
                        (sidebarClick)="onSidebarClick()" [isSidebarExpanded]="isSidebarExpanded" [hideMenuButton]="hideMenuButton"
                        [customNewButtonRouterLinks]="customNewButtonRouterLinks">
          </app-toolbar>
        </header>
      </div>

      <div class="height-auto" [class.mobile]="mobileQuery.matches" fxFlex="grow">
        <mat-drawer-container [ngClass]="{ 'reporting-route': section === SECTIONS.REPORTING }">
          <mat-drawer *ngIf="mobileQuery.matches || (showBars && !hideSidebar)" #snav
                      [mode]="mobileQuery.matches ? 'over' : 'side'"
                      [opened]="!mobileQuery.matches && showBars" ngClass.lt-md="mobile"
                      [class.expanded]="isSidebarExpanded">
            <app-sidebar *ngIf="member && !mobileQuery.matches && !hideSidebar"
                                      [member]="member"
                                      [section]="section"
                                      [companySettings]="companySettings"
                                      [isSidebarExpanded]="isSidebarExpanded">
            </app-sidebar>
            <app-navigation-sidebar *ngIf="member && mobileQuery.matches"
                                    [isAdmin]="isAdmin" [showGamificationBadge]="showGamificationBadge"></app-navigation-sidebar>
          </mat-drawer>

          <mat-drawer-content [class.expanded]="isSidebarExpanded" [class.has-sidebar]="!mobileQuery.matches && showBars && !hideSidebar">
            <router-outlet></router-outlet>
          </mat-drawer-content>
        </mat-drawer-container>
      </div>
    </div>
  </main>
  <div *ngIf="mobileQuery.matches && !snav?.opened">
    <app-mobile-actions-bar [disableActions]="disableActions" [hideActions]="hideActions"
      (actionOne)="actionOne()" (actionTwo)="actionTwo()" (actionThree)="actionThree()" (actionFour)="actionFour()"></app-mobile-actions-bar>
  </div>
</ng-template>

<ng-template #notLoggedIn>
  <app-alert></app-alert>
  <router-outlet></router-outlet>
</ng-template>

<app-notification-modal-dialog></app-notification-modal-dialog>
