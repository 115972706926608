import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '@environment';
import dayjs from 'dayjs';

import {
  DatastoreConfig,
  JsonApiDatastore,
  JsonApiDatastoreConfig,
  JsonApiModel,
  JsonApiQueryData,
  ModelType,
} from '@elearnio/angular2-jsonapi';

import { CourseAssignment } from '@models/course-assignment.model';
import { CourseActivitySection } from '@models/course/course-activity-section.model';
import { CourseActivity } from '@models/course/course-activity.model';
import { CourseCategory } from '@models/course-category.model';
import { Course } from '@models/course.model';
import { CompanyEmailTemplate } from '@models/template/company-email-template.model';
import { SystemEmailTemplate } from '@models/template/system-email-template.model';
import { GamificationLevel } from '@models/gamification/gamification-level.model';
import { Group } from '@models/group.model';
import { Member } from '@models/member.model';
import { NotificationSettings } from '@models/notification-settings.model';
import { User } from '@models/user.model';
import { Gamification } from '@models/gamification/gamification.model';
import { EmailRegistration } from '@models/auth/email-registration.model';
import { ConfirmationValidation } from '@models/auth/token-validation-confirmation.model';
import { CompanyAdminRegistration } from '@models/auth/company-admin-registration.model';
import { CertificateTemplate } from '@models/template/certificate-template.model';
import { Certificate } from '@models/template/certificate.model';
import { PasswordRecovery } from '@models/auth/password-recovery.model';
import { PasswordReset } from '@models/auth/password-reset.model';
import { CourseReport } from '@models/course/course-report.model';
import { CourseMemberReport } from '@models/course/member/course-member-report.model';
import { MemberReport } from '@models/member/member-report.model';
import { GroupReport } from '@models/group/group-report.model';
import { CourseMemberResult } from '@models/course/member/course-member-result.model';
import { CourseMemberAnswer } from '@models/course/member/course-member-answer.model';
import { MemberNotification } from '@models/member/member-notification.model';
import { MemberOption } from '@models/member/member-option.model';
import { DashboardTopCourse } from '@models/dashboard/dashboard-top-courses.model';
import { DashboardMyTask } from '@models/dashboard/dashboard-my-tasks.model';
import { Integration } from '@models/integrations.model';
import { LearningPath } from '@models/learning-path.model';
import { LearningPathAssignment } from '@models/learning-path-assignment.model';
// tslint:disable-next-line:max-line-length
import { LearningPathMemberResult } from '@models/learning-path/member/learning-path-member-result.model';
import { LearningPathMember } from '@models/learning-path/member/learning-path-member.model';
import { LearningPathCourseSection } from '@models/learning-path/learning-path-course-section.model';
import { LearningPathSectionCourseOrder } from '@models/learning-path/learning-path-section-course-order.model';
import { LearningPathSectionLabel } from '@models/learning-path/learning-path-section-label.model';
import { LearningPathReminder } from '@models/learning-path/learning-path-reminder.model';
// tslint:disable-next-line:max-line-length
import { DashboardLearnTimeCurrentYear } from '@models/dashboard/dashboard-learn-time-current-year.model';
import { CourseReminder } from '@models/course/course-reminder.model';
import { CourseReminderType } from '@models/course/course-reminder-types.model';
import { PhysicalLocation } from '@models/physical-location.model';
import { VirtualLocation } from '@models/virtual-location.model';
import { ILTCourse } from '@models/ilt-course.model';
import { ILTCourseEvent } from '@models/ilt-course/ilt-course-event.model';
import { ILTCourseEventDay } from '@app/models/ilt-course/ilt-course-event-day.model';
import { ILTCourseAssignment } from '@models/ilt-course-assignment.model';
import { ILTCourseEventAssignment } from '@models/ilt-course/ilt-course-event-assignment.model';
import { ILTCourseMemberResult } from '@app/models/ilt-course/member/ilt-course-member-result.model';
import { ILTCertificate } from '@models/template/ilt-certificate.model';
import { Country } from '@models/country.model';
import { OrganizationLevel } from '@app/models/organization/organization-level.model';
import { OrganizationLevelCourse } from '@app/models/organization/organization-level-course.model';
import { OrganizationLevelIltCourse } from '@app/models/organization/organization-level-ilt-course.model';
import { OrganizationLevelLearningPath } from '@app/models/organization/organization-level-learning-path.model';
import { VisibilityPermission } from '@app/models/organization/visibility-permission.model';
import { OrganizationLevelPermission } from '@app/models/organization/organization-level-permission.model';
import { catchError, Observable, take } from 'rxjs';
import { GroupRule } from '@models/group-rule.model';
import { LearningPathReminderType } from '@app/models/learning-path/learning-path-reminder-types.model';
import { BrandPalette } from '@models/branding/brand-palette.model';
import { TaskActivityReview } from '@models/task-activity-review';
import { TrainingSchedule } from '@models/training-schedule/training-schedule.model';
import { TrainingScheduleItem } from '@models/training-schedule/training-schedule-item.model';
import { TrainingScheduleCohort } from '@models/training-schedule/training-schedule-cohort.model';
import { TrainingScheduleCohortItem } from '@models/training-schedule/training-schedule-cohort-item.model';
import { TrainingScheduleCohortMember } from '@models/training-schedule/training-schedule-cohort-member.model';
import { CompanyNewsSetting } from '@app/models/company/company-news-settings.model';
import { CohortAssignment } from '@models/training-schedule/training-schedule-cohort-assignment.model';
import { Meeting } from '@app/models/meeting.model';
import { Block } from '@app/models/block.model';
import { TrainingScheduleIltCourse } from '@app/models/training-schedule/training-schedule-ilt-course.model';
import { Holiday } from '@models/holiday.model';

// disable because of path_mapping
/* eslint-disable camelcase */
const config: DatastoreConfig = {
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  models: {
    course_assignments: CourseAssignment,
    course_activity_sections: CourseActivitySection,
    course_activities: CourseActivity,
    course_categories: CourseCategory,
    courses: Course,
    company_email_templates: CompanyEmailTemplate,
    system_email_templates: SystemEmailTemplate,
    gamification_levels: GamificationLevel,
    groups: Group,
    members: Member,
    member_notifications: MemberNotification,
    member_options: MemberOption,
    notification_settings: NotificationSettings,
    users: User,
    gamifications: Gamification,
    email_registration: EmailRegistration,
    password_recovery: PasswordRecovery,
    confirmation_validation: ConfirmationValidation,
    company_admin_registrations: CompanyAdminRegistration,
    certificate_templates: CertificateTemplate,
    certificates: Certificate,
    password_resets: PasswordReset,
    course_reports: CourseReport,
    member_reports: MemberReport,
    group_reports: GroupReport,
    course_member_reports: CourseMemberReport,
    course_member_answers: CourseMemberAnswer,
    course_member_results: CourseMemberResult,
    dashboard_top_courses: DashboardTopCourse,
    dashboard_my_tasks: DashboardMyTask,
    dashboard_learn_time_current_year: DashboardLearnTimeCurrentYear,
    api_configs: Integration,
    learning_paths: LearningPath,
    learning_path_assignments: LearningPathAssignment,
    learning_path_courses_sections: LearningPathCourseSection,
    learning_path_section_courses_orders: LearningPathSectionCourseOrder,
    learning_path_section_labels: LearningPathSectionLabel,
    reminders: CourseReminder,
    physical_locations: PhysicalLocation,
    reminder_types: CourseReminderType,
    virtual_locations: VirtualLocation,
    ilt_courses: ILTCourse,
    events: ILTCourseEvent,
    event_days: ILTCourseEventDay,
    ilt_course_assignments: ILTCourseAssignment,
    ilt_event_assignments: ILTCourseEventAssignment,
    ilt_courses_member_results: ILTCourseMemberResult,
    countries: Country,
    learning_path_results: LearningPathMemberResult,
    learning_path_members: LearningPathMember,
    ilt_certificates: ILTCertificate,
    learning_path_reminders: LearningPathReminder,
    learning_path_reminder_types: LearningPathReminderType,
    organisation_levels: OrganizationLevel,
    organisation_levels_courses: OrganizationLevelCourse,
    organisation_levels_ilt_courses: OrganizationLevelIltCourse,
    organisation_levels_learning_paths: OrganizationLevelLearningPath,
    visibility_permissions: VisibilityPermission,
    level_permissions: OrganizationLevelPermission,
    group_rules: GroupRule,
    brand_palettes: BrandPalette,
    company_news_settings: CompanyNewsSetting,
    task_activity_reviews: TaskActivityReview,
    schedules: TrainingSchedule,
    schedule_items: TrainingScheduleItem,
    cohorts: TrainingScheduleCohort,
    cohort_items: TrainingScheduleCohortItem,
    cohort_assignments: CohortAssignment,
    cohort_members: TrainingScheduleCohortMember,
    meetings: Meeting,
    blocks: Block,
    scheduled_ilt_courses: TrainingScheduleIltCourse,
    holidays: Holiday,
  },
};

/* eslint-enable camelcase */

@Injectable({ providedIn: 'root' })
@JsonApiDatastoreConfig(config)
export class Datastore extends JsonApiDatastore {
  constructor(http: HttpClient) {
    super(http);
  }

  deleteRecordWithBody<T extends JsonApiModel>(
    modelType: ModelType<T>,
    model: T,
    headers?: HttpHeaders,
    customUrl?: string,
  ): Observable<Response> {
    const relationships = this.getRelationships(model);
    const body = {
      data: {
        relationships,
        type: modelType,
        id: model.id,
      },
    };

    const requestOptions = this['buildRequestOptions']({ headers, body });
    const url = this.buildUrl(modelType, null, model.id, customUrl);
    return this.http
      .delete(url, requestOptions)
      .pipe(catchError(res => this.handleError(res)));
  }

  bulkUpdate<T extends JsonApiModel>(
    modelType: ModelType<T>,
    models: T[],
    allowedRelationships?: string[],
    headers?: HttpHeaders,
  ): Observable<Record<string, any>> {
    const type = Reflect.getMetadata('JsonApiModelConfig', modelType).type;
    const localPath = `/api/v1/${type}/bulk_update`;
    const path = `${environment.backendBaseUrl}${localPath}`;

    const relationships = this.getAllowedRelationships(
      models[0],
      allowedRelationships,
    );
    const data = models.map(model =>
      this.transformPropertyNamesToSerializedNames(modelType, model),
    );
    const body = {
      data: {
        relationships,
        [type]: {
          data,
        },
      },
    };
    const url = this.buildUrl(modelType, null, undefined, path);

    // Force recreate instead of peek
    if (this['internalStore'][type]) {
      models.forEach((model: T) => {
        this['internalStore'][type][model.id] = null;
      });
    }

    return this.http.put(url, body, { headers }).pipe(
      take(1),
      catchError(res => this.handleError(res)),
    );
  }

  public datastoreExtractQueryData<T extends JsonApiModel>(
    response: HttpResponse<object>,
    modelType: ModelType<T>,
    withMeta?: boolean,
  ): Array<T> | JsonApiQueryData<T> {
    return this.extractQueryData(response, modelType, withMeta);
  }

  private getAllowedRelationships<T extends JsonApiModel>(
    model: T,
    allowedRelationships?: string[],
  ): any {
    const relationships = this.getRelationships(model);
    let result: any;
    allowedRelationships?.forEach(key => {
      if (relationships[key]) {
        if (!result) {
          result = {};
        }
        result[key] = relationships[key];
      }
    });

    return result;
  }

  private transformPropertyNamesToSerializedNames<T extends JsonApiModel>(
    modelType: ModelType<T>,
    model: T,
  ) {
    const serializedNameToPropertyName = this.getModelPropertyNames(
      modelType.prototype,
    );
    const attributes: any = {};

    Object.keys(serializedNameToPropertyName).forEach(serializedName => {
      if (
        model &&
        model[serializedNameToPropertyName[serializedName]] !== undefined
      ) {
        if (
          model[serializedNameToPropertyName[serializedName]] instanceof Date
        ) {
          attributes[serializedName] = dayjs(
            model[serializedNameToPropertyName[serializedName]],
          ).format('YYYY-MM-DDTHH:mm:ss.sssZ');
        } else {
          attributes[serializedName] =
            model[serializedNameToPropertyName[serializedName]];
        }
      }
    });

    return {
      type: model.modelConfig.type,
      id: model.id,
      attributes,
    };
  }
}
