export const ILT_COURSES_ATTENDANCE_STATUS = {
  absent: 'absent',
  attended: 'attended',
};

export const ILT_COURSES_VIRTUAL_LOCATION_TYPE = {
  OTHER: 'other',
  MICROSOFT: 'graph_api',
  GOOGLE: 'google_meet',
};

export const ILT_COURSES_EVENT_TYPES = {
  PHYISICAL: 'physical',
  VIRTUAL: 'virtual',
};

export const INTEGRATION_TYPES = {
  GOOGLE: 'google',
  MICROSOFT: 'microsoft',
};
