import {
  RouteActionButtonInterface,
  RouteActionInterface,
} from '../../interfaces/route-action.interface';

const saveButton: RouteActionButtonInterface = {
  action: 'doActionOne',
  label: 'common.save',
  color: 'yellow',
  class: ['filled', 'no-border'],
  name: 'save',
};

const cancelButton: RouteActionButtonInterface = {
  action: 'doActionTwo',
  label: 'common.cancel',
  color: 'darkblue',
  name: 'cancel',
};

const deleteButton: RouteActionButtonInterface = {
  action: 'doActionThree',
  label: 'common.delete',
  color: 'darkblue',
  name: 'delete',
};

const previewButton: RouteActionButtonInterface = {
  action: 'doActionFour',
  label: 'common.preview',
  color: 'darkblue',
  name: 'preview',
};

const saveAndSync: RouteActionButtonInterface = {
  action: 'doActionOne',
  label: 'settings.organization.saveAndSync',
  color: 'yellow',
  class: ['filled', 'no-border'],
  name: 'saveAndSync',
};

const newItemButton = (
  key: string,
  routerLink = '',
  canBeHidden = false,
): RouteActionButtonInterface => ({
  label: key,
  color: 'yellow',
  class: ['filled', 'no-border'],
  name: 'new',
  icon: 'add',
  routerLink,
  canBeHidden,
});

export const routeActions: RouteActionInterface[] = [
  {
    // Routes with navigation via toolbar
    routeNames: ['/courses/list'],
    buttons: [
      {
        action: 'importCourse',
        label: '',
        icon: 'cloud_upload',
        color: 'darkblue',
        name: 'importCourse',
        class: ['import-course'],
      },
      newItemButton('toolbar.newCourse', '/courses/new/profile', true),
    ],
  },
  {
    routeNames: ['/categories/list'],
    buttons: [newItemButton('toolbar.newCategory', '/categories/new/profile')],
  },
  {
    routeNames: ['/learning-paths/list'],
    buttons: [
      newItemButton(
        'toolbar.newLearningPath',
        '/learning-paths/new/profile',
        true,
      ),
    ],
  },
  {
    routeNames: ['/ilt-courses/list'],
    buttons: [
      newItemButton(
        'toolbar.newLiveTraining',
        '/ilt-courses/new/profile',
        true,
      ),
    ],
  },
  {
    routeNames: ['/ilt-courses/instructors-list'],
    buttons: [
      {
        action: 'doActionOne',
        label: 'common.today',
        icon: 'today',
        color: 'darkblue',
      },
    ],
  },
  {
    routeNames: ['/training-schedules/list'],
    buttons: [
      newItemButton('toolbar.newSchedule', '/training-schedules/new/profile'),
    ],
  },
  {
    routeNames: [/^\/training-schedules\/[0-9]+\/cohorts$/],
    buttons: [newItemButton('toolbar.newCohort')],
  },
  {
    routeNames: ['/organizations/list', /^\/organizations\/[0-9]+\/members$/],
    buttons: [],
  },
  {
    routeNames: [/^\/organizations\/[0-9]+\/trainings$/],
    buttons: [
      {
        action: 'doActionOne',
        label: 'organization.trainings.assign',
        color: 'yellow',
        class: ['filled', 'no-border'],
        name: 'assignTrainings',
      },
    ],
  },
  {
    routeNames: [/^\/organizations\/[0-9]+\/permissions$/],
    buttons: [
      {
        action: 'doActionOne',
        label: 'organization.permissions.addUser',
        color: 'darkblue',
        class: [],
        name: 'addPeople',
        icon: 'group_add',
      },
      {
        action: 'doActionTwo',
        label: 'common.save',
        color: 'yellow',
        class: ['filled', 'no-border'],
        name: 'savePermissions',
      },
    ],
  },
  {
    routeNames: ['/talents/list'],
    buttons: [
      {
        action: 'inviteByEmail',
        label: 'toolbar.inviteEmail',
        icon: 'mail',
        color: 'darkblue',
        canBeHidden: true,
      },
      {
        action: 'inviteByCsv',
        label: 'toolbar.inviteCsv',
        icon: 'attach_file',
        color: 'darkblue',
        canBeHidden: true,
      },
      newItemButton('toolbar.newMember', '/talents/new', true),
    ],
  },
  {
    routeNames: ['/groups/list'],
    buttons: [newItemButton('toolbar.newGroup', '/groups/new/profile')],
  },
  {
    routeNames: ['/settings/certificates'],
    buttons: [newItemButton('common.new', '/settings/certificates/new')],
  },
  {
    routeNames: ['/settings/learning-history'],
    buttons: [newItemButton('common.new', '/settings/learning-history/new')],
  },
  {
    routeNames: ['/settings/integrations'],
    buttons: [newItemButton('common.new', '/settings/integrations/new')],
  },
  {
    routeNames: ['/settings/branding/list'],
    buttons: [newItemButton('common.new', '/settings/branding/new', true)],
  },
  {
    routeNames: ['/settings/news/list'],
    buttons: [newItemButton('common.new', '/settings/news/new', true)],
  },
  {
    routeNames: [
      '/courses/my',
      '/courses/catalogue',
      '/courses/task-activity-review',
      '/learning-paths/my',
      '/ilt-courses/my',
      '/reporting/groups',
      '/reporting/talents',
      '/reporting/trainings',
      '/reporting/courses',
      '/reporting/ilt-courses',
      '/reporting/learning-paths',
      '/reporting/schedules',
      '/settings/custom-email/list',
      /^\/reporting\/courses\/\d+(?:&?[^=&]*=[^=&]*)*$/,
      /^\/reporting\/learning-paths\/\d+(?:&?[^=&]*=[^=&]*)*$/,
      /^\/reporting\/ilt-courses\/\d+(?:&?[^=&]*=[^=&]*)*$/,
      /^\/reporting\/schedules\/\d+\/cohorts$/,
      /^\/reporting\/schedules\/\d+\/cohorts\/\d+\/members$/,
      /^\/reporting\/talents\?.*/,
      /^\/reporting\/courses\?.*/,
      /^\/reporting\/trainings\?.*/,
      /^\/reporting\/learning-paths\?.*/,
      /^\/reporting\/schedules\?.*/,
      /^\/reporting\/ilt-courses\?.*/,
    ],
    buttons: [],
  },
  // Routes with actions via toolbar
  {
    routeNames: [
      '/categories/new/profile',
      '/groups/new/profile',
      '/talents/new',
      '/my/profile',
      '/settings/company',
      '/settings/gamification',
      '/settings/authentication',
      '/settings/branding/new',
      '/settings/news/new',
      /^\/settings\/custom-email\/[0-9]+$/,
    ],
    buttons: [cancelButton, saveButton],
  },
  {
    routeNames: [
      /^\/categories\/[0-9]+\/profile$/,
      /^\/groups\/[0-9]+\/profile$/,
      /^\/talents\/[0-9]+$/,
      /^\/settings\/news\/[0-9]+$/,
    ],
    buttons: [cancelButton, deleteButton, saveButton],
  },
  // Settings
  {
    routeNames: [
      '/settings/certificates/new',
      /^\/settings\/certificates\/[0-9]+$/,
    ],
    buttons: [cancelButton, previewButton, saveButton],
  },
  {
    routeNames: [
      '/settings/learning-history/new',
      /^\/settings\/learning-history\/[0-9]+$/,
    ],
    buttons: [cancelButton, previewButton, saveButton],
  },
  {
    routeNames: [
      '/settings/integrations/new',
      /^\/settings\/integrations\/[0-9]+$/,
    ],
    buttons: [
      cancelButton,
      {
        action: 'doActionThree',
        label: 'settings.integrations.personio.sync',
        color: 'darkblue',
        class: ['filled', 'no-border'],
        name: 'sync',
      },
      {
        action: 'doActionOne',
        label: 'common.save',
        color: 'yellow',
        class: ['filled', 'no-border'],
        name: 'save',
      },
    ],
  },
  {
    routeNames: ['/settings/organization'],
    buttons: [cancelButton, saveAndSync],
  },
  {
    routeNames: [/^\/settings\/branding\/[0-9]+$/],
    buttons: [cancelButton, deleteButton, saveButton],
  },
  {
    routeNames: [/^\/courses\/task-activity-review\/[0-9]+$/],
    buttons: [saveButton],
  },
];
