import { Injectable } from '@angular/core';

export class TrainingsFilters {
  public static readonly coursesFilters = 'TrainingCourses';
  public static readonly trainingsFilters = 'TrainingsTrainings';
  public static readonly learningPathsFilters = 'TrainingsLearningPaths';
  public static readonly categoriesFilters = 'TrainingsCategories';

  public static readonly myIltCoursesFilters = 'TrainingMyTrainings';
  public static readonly myLearningPathsFilters = 'TrainingsMyLearningPaths';
  // The following two are mistakenly inversed and kept like this because of backwards compatibility
  public static readonly myCoursesFilters = 'TrainingMyCourses';
  public static readonly trainingCatalogueFilters =
    'TrainingsTrainingCatalogue';

  public static readonly peoplePeopleFilters = 'PeoplePeople';
  public static readonly peopleGroupFilters = 'PeopleGroup';

  public static readonly organizationAssignCheckSubunits =
    'OrganizationAssignCheckSubunits';
  public static readonly orgaMemberFilters = 'OrgaMembers';
  public static readonly orgaTrainingFilters = 'OrgaTrainings';

  public static readonly taskReviewFilters = 'TaskReviews';
  public static readonly trainingScheduleFilters = 'TrainingSchedule';

  public static readonly integrationsTable = 'IntegrationsTable';
  public static readonly brandingsTable = 'BrandingsTable';
  public static readonly newsTable = 'NewsTable';
  public static readonly certificatesTable = 'CertificatesTable';
  public static readonly learningHistoryTable = 'LearningHistoryTable';
  public static readonly emailTemplatesFilters = 'emailTemplatesFilters';

  public static readonly reportingGroupsTable = 'ReportingGroupsTable';
  public static readonly reportingMembersTable = 'ReportingMembersTable';
  public static readonly reportingTrainingsTable = 'ReportingTrainingsTable';
  public static readonly reportingCoursesTable = 'ReportingCoursesTable';
  public static readonly reportingLearningPathsTable =
    'ReportingLearningPathsTable';
  public static readonly reportingIltCoursesTable = 'ReportingIltCoursesTable';
  public static readonly reportingSchedulesTable = 'ReportingSchedulesTable';
  public static readonly reportingCohortsTable = 'ReportingCohortsTable';
  public static readonly reportingCohortMembersTable =
    'ReportingCohortMembersTable';
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify({ value }));
  }

  getItem<T>(key: string): T | any {
    const data: string | null = localStorage.getItem(key);

    if (data !== null) {
      return JSON.parse(data).value;
    }

    return {};
  }
}
