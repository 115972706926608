import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-training-status',
  templateUrl: './training-status.component.html',
  styleUrls: ['./training-status.component.scss'],
})
export class TrainingStatusComponent {
  @Input() type = '';
  @Input() isNew = false;
  @Input() isNewText = '';
  @Input() isInProgress = false;
  @Input() isInProgressText = '';
  @Input() isFinished = false;
  @Input() isFinishedText = '';
  @Input() isFailed = false;

  constructor() {}
}
