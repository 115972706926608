import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ToolbarActionsService } from '@services/toolbar-actions.service';
import { RouteActionInterface } from '@core/interfaces/route-action.interface';

@UntilDestroy()
@Component({
  selector: 'app-mobile-actions-bar',
  templateUrl: './mobile-actions-bar.component.html',
  styleUrls: ['./mobile-actions-bar.component.scss'],
})
export class MobileActionsBarComponent implements OnInit {
  @Input() disableActions: string[] = [];
  @Input() hideActions: string[] = [];
  @Output() actionOne = new EventEmitter<boolean>();
  @Output() actionTwo = new EventEmitter<boolean>();
  @Output() actionThree = new EventEmitter<boolean>();
  @Output() actionFour = new EventEmitter<boolean>();
  actions!: RouteActionInterface | undefined;

  constructor(
    private toolbarActionsService: ToolbarActionsService,
    private router: Router,
  ) {
    this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.actions = this.toolbarActionsService.getActionsForCurrentRoute(
          event.url,
        );
      }
    });
  }

  ngOnInit(): void {}

  doActionOne(): void {
    this.actionOne.emit(true);
  }

  doActionTwo(): void {
    this.actionTwo.emit(true);
  }

  doActionThree(): void {
    this.actionThree.emit(true);
  }

  doActionFour(): void {
    this.actionFour.emit(true);
  }

  runAction(action: string | undefined): void {
    if (!action) {
      return;
    }
    // typeof this[action] === 'function' throws typescript errors
    switch (action) {
      case this.doActionOne.name:
        this.doActionOne();
        break;
      case this.doActionTwo.name:
        this.doActionTwo();
        break;
      case this.doActionThree.name:
        this.doActionThree();
        break;
      case this.doActionFour.name:
        this.doActionFour();
        break;
    }
  }

  disableAction(action: string | undefined): boolean {
    return !!action && !!this.disableActions.includes(action);
  }

  hideAction(action: string | undefined): boolean {
    return !!action && !!this.hideActions.includes(action);
  }
}
